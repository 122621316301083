import React, { useState } from "react";

const Modal2 = () => {
  const [showModal, setShowModal] = useState(true);
  return (
    <>
      {showModal ? (
        <>
          <div
            className="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="">
                    <div className="sm:flex sm:items-start">
                      <button
                        className="absolute top-3 right-3 px-2 rounded-full text-[#fff] hover:text-[#ebebeb] bg-red-500 hover:bg-red-600"
                        id="modal-title"
                        onClick={() => setShowModal(false)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                      <div className="w-full flex justify-center item-center flex-col ">
                        <img
                        src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Post%2FEspinoza%20Septic%20Service.jpg?alt=media&token=36ed6256-9d97-4eb5-9b88-903e57688709"
                        className="w-full h-full"
                        alt="Not Found"
                        />
                      </div>
                    </div>
                  </div>
                    
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal2;
